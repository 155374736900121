// socket.js
import { ref } from 'vue';
import { io } from 'socket.io-client';

const socket = ref(null);
const userData = ref({}); // This will store individual user data (id, username, avatar)
const users = ref([]); // This will store users fetched from API
const connectedUsers = ref([]); // This will store connected users from WebSocket

// Initialize the WebSocket connection
export function useSocket() {
  if (!socket.value) {
    socket.value = io('https://websocket.digitweaks.com:3000', {
      path: '/socket/',
      transport: ['websocket'],
      reconnect: true,
    });
  }

  // Fetch user data from the API
  function fetchUserData() {
    return fetch('/api/user/') // Replace with your actual API endpoint
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        return response.json();
      })
      .then(data => {
        userData.value = { // Store user data
          userId: data.id,
          fullname: data.name,
          username: data.username,
          avatar: data.avatar
        };
        // Emit to WebSocket that this user has registered
        socket.value.emit('registerUser', {
          userID: data.id,
          username: data.username
        });
        return data; // Return the fetched data
      })
      .catch(error => {
        console.error('Error in fetching user data:', error);
      });
  }

  function fetchUsers(){
      fetch('/api/users') // Adjust endpoint as needed
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch users');
          }
          return response.json();
        })
        .then(data => {
          this.users = data; // Store users in the data property
        })
        .catch(error => {
          console.error('Error in fetching user data:', error);
        });
    }

    function fetchConnectedUsers() {
      // Emit an event to get connected users from the server
      this.socket.emit('fetchConnectedUsers');

      // Listen for the response containing connected users
      this.socket.on('connectedUsersList', (users) => {
          this.users = users; // Populate the users array
          //console.log(this.users);
      });
    } 

    function mentionUser(userID, toastComment, url = null) { 
      
        const toastData = {
            origin: 'Check this out!',
            title: this.userData.username + ' mentioned you',
            color: 'black',
            message: toastComment,
            url: url, 
            type: 'success'
        }

        //console.log('user ID' + userID);
      // Emit the toast notification to the server
        this.socket.emit('sendToast', { userID, toast: toastData });
    }

  return { 
    socket, 
    userData,
    users, 
    connectedUsers,
    fetchUserData, // Expose fetchUserData function
    fetchUsers,
    fetchConnectedUsers,
    mentionUser
  };
}

