<template>

  <div class="toast" id="liveToast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="true" style="position: fixed; top: 1rem; right: 1rem; opacity: 1;"> 
      <div class="toast-header">
          <strong class="me-auto" id="toast-origin">$ToastOrigin</strong>
          <small id="toast-title" style="color: $ColorToastTitle;">$ToastTitle</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body" id="toast-message" v-html="toastMessage" style="background-color: rgba(255, 255, 255, 1); opacity: 1;">

      </div>
  </div>
 
</template>
 
 
<script>
// Import Socket.IO client
import { useSocket } from '../utils/websocket'; // Import the shared socket utility
import dayjs from 'dayjs';  // Import dayjs
import relativeTime from 'dayjs/plugin/relativeTime';
import Cookies from 'js-cookie';

// Extend dayjs to use the relativeTime plugin
dayjs.extend(relativeTime);

export default {
  setup() {
    const { socket, userData, fetchUserData, users, connectedUsers, fetchUsers, fetchConnectedUsers } = useSocket();

    // Return everything you want to access outside of setup
    return {
      socket,
      userData,
      fetchUserData,
      users,
      connectedUsers,
      fetchUsers,
      fetchConnectedUsers,
    };

  },
  data() {
    return {
      socket: null,
      supportsTouch: 'ontouchstart' in window || navigator.maxTouchPoints > 0, 
    };
  },
  methods: {
    fetchToast() {
        fetch('/api/getToast')
            .then(response => response.json())
            .then(toastData => {
                if (toastData && toastData.toasts) {
                    // Loop through each toast in the array
                    toastData.toasts.forEach(toast => {
                        this.showNewMessageToast(
                            toast.origin,   // origin
                            toast.title,    // title
                            toast.color,    // color
                            toast.message,  // message
                            toast.type,      // type
                            toast.url
                        );
                    });
                }
            })
            .catch(error => console.error('Error fetching toast:', error));
    },
    showToast(dataToast) {
        const toastEl = document.querySelector('#liveToast');  // Use the correct ID
        const toastMessage = document.getElementById('toast-message');
        const toastTitle = document.getElementById('toast-title');
        const toastOrigin = document.getElementById('toast-origin');
        //const toastType = document.getElementById('toast-type');

        // Check if toastEl and its elements are found in the DOM
        if (!toastEl || !toastMessage || !toastTitle || !toastOrigin) {
            console.log('Toast element, message, title, or origin not found.');
            return;
        }

        // Update the toast content dynamically using the dataToast object
        
        toastTitle.textContent = dataToast.title;
        toastMessage.textContent = dataToast.message;
        toastTitle.style.color = dataToast.color;
        //toastType.textContent = dataToast.type;

        
        // If a URL is provided, wrap the message inside a clickable <a> tag
        if (dataToast.url) {
            toastOrigin.innerHTML = `<a href="${dataToast.url}" target="_blank" style="text-decoration:none;">${dataToast.origin}</a>`;
        } else {
              toastOrigin.textContent = dataToast.origin;
        }



        // Initialize and show the toast
        const toast = new bootstrap.Toast(toastEl);
        toast.show();

        // Clear toast after showing (if you need to)
        fetch('/api/clearToast');
    }

  },
  mounted() {

    this.fetchUserData()
      .then(() => {
        this.fetchConnectedUsers();
      }); 

    this.fetchToast(); 

    this.socket.on('toast', (toast) => {
      this.showToast(toast); 
    });

  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}; 

</script>